<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="optionUpdating" class="modal-card-title">
              Atualizar informações da opção
            </p>
            <p v-else class="modal-card-title">
              Insira a opção de menu a ser cadastrada
            </p>
          </header>
          <section class="modal-card-body">
          <b-field>
            <template #label>
               Números que irão enviar a opção
                <b-tooltip type="is-success" size="is-large" multilined="true" label="Selecione os números de telefone para os quais a opção de menu deve ser enviada.">
                    <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
                </b-tooltip>
            </template>
            <multiselect v-model="phones" :multiple="true" :options="availablePhones" label="phone" track-by="phone"
              select-label="" deselect-label="" @input="verifyOptionValue">
              <template slot="placeholder">Todos os números</template>
            </multiselect>
          </b-field>
            <div class="notification is-warning" v-if="phones.length === 0">
              Caso não seja informado um número, esta opção será enviada por todos os números!
            </div>
            <hr>
            <b-field>
              <template #label>
                Opção de menu anterior a esta
                <b-tooltip type ="is-success" size="is-medium" multilined ="true" position="is-top" label ='Use este campo para criar um submenu. Ao ser informado, essa opção só será exibida depois que o contato selecionar a opção "pai". '>
                  <b-icon type="is-primary" size="is-small" icon="help-circle"></b-icon>
                </b-tooltip>
              </template>
              <b-select placeholder="Opção de menu anterior a esta" v-model="previous_option_id" expanded
                @input="verifyOptionValue">
                <option :value="null"></option>
                <option v-for="option in menuOptions" :value="option.id" :key="option.id">
                  {{ option.description }}
                </option>
              </b-select>
            </b-field>
            <b-field :message="invalidOptionValue.message" :type="invalidOptionValue.style">
              <template #label>
                Atalho da opção
                <b-tooltip type ="is-success" size="is-small" multilined ="true" position="is-top" label = "Define o número que os usuários usarão para acessar a opção do menu.">
                  <b-icon type="is-primary" size="is-small" icon="help-circle"></b-icon>
                </b-tooltip>
              </template>
              <b-input type="text" v-model="option_value" placeholder="Atalho da opção" @input="verifyOptionValue"
                required>
              </b-input>
            </b-field>
            <b-field>
              <template #label>
                Descrição
                <b-tooltip type ="is-success" size="is-small" multilined ="true" position="is-top" label = "Define o texto que irá acompanhar o número definido em Atalho da opção.">
                  <b-icon type="is-primary" size="is-small" icon="help-circle"></b-icon>
                </b-tooltip>
              </template>
              <b-input type="text" v-model="description" placeholder="Descrição" required>
              </b-input>
            </b-field>
            <b-field>
              <template #label>
                Ação da opção
                <b-tooltip type ="is-success" size="is-medium" multilined ="true" position="is-top">
                  <template v-slot:content>
                    O que a opção de menu fará quando for selecionada. <br>
                    <p v-if="option_type === 'RED'"> <b>Redirecionar para departamento:</b> redirecionará o contato para o departamento selecionado.</p>
                    <p v-if="option_type === 'MEN'"> <b>Mostra menu:</b> Definirá essa opção como menu "pai". Ao ser selecionada, exibirá ás opções vinculadas a ela pelo o campo "Opção de menu anterior a esta".</p>
                    <p v-if="option_type === 'INF'"> <b>Informativa:</b> enviará uma mensagem e/ou arquivo</p>
                    <p v-if="option_type === 'TAG'"> <b>Atribuição automática de Tag:</b> a tag informada será vinculada ao cadastro do contato.</p>
                    <p v-if="option_type === 'INT'"> <b>Integração com sistema externo:</b> iniciará uma requisição com sistema externo informado.</p>
                  </template>
                  <b-icon type="is-primary" size="is-small" icon="help-circle"></b-icon>
                </b-tooltip>
              </template>
              <b-select placeholder="Selecione uma opção" v-model="option_type" required expanded>
                <option value="RED" key="RED">
                  Redirecionar para departamento
                </option>
                <option value="MEN" key="MEN">Mostrar menus</option>
                <option value="INF" key="INF">Opção informativa</option>
                <option value="TAG" key="TAG">Atribuição automática de tag</option>
                <option value="INT" key="INT">Integração com sistema externo</option>
              </b-select>
            </b-field>
            <b-field v-if="option_type === 'INT'">
              <template #label>
                Integração com sistema externo
                <b-tooltip type ="is-success" size="is-small" multilined ="true" position="is-top" label = "Escolha a integração que deverá ser vinculada a essa opção de menu.">
                  <b-icon type="is-primary" size="is-small" icon="help-circle"></b-icon>
                </b-tooltip>
              </template>
              <b-select placeholder="Integração de sistema ligada a esta opção" v-model="system_integration_id" expanded>
                <option :value="null"></option>
                <option v-for="integration in systems_integrations" :value="integration.id" :key="integration.id">
                  {{ integration.external_system.name }} - {{ integration.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Enviar menu" v-if="option_type === 'INF'">
              <b-checkbox v-model="info_option_send_main_menu">Enviar menu novamente após seleção da opção</b-checkbox>
            </b-field>
            <b-field label="Departamento" v-if="option_type === 'RED'">
              <template #label>
                Departamento
                <b-tooltip type ="is-success" size="is-small" multilined ="true" position="is-top" label = "Escolha o departamento no qual será feito o redirecionamento.">
                  <b-icon type="is-primary" size="is-small" icon="help-circle"></b-icon>
                </b-tooltip>
              </template>
              <b-select placeholder="Selecione uma opção" v-model="department_id" required expanded>
                <option v-for="department in filteredDepartments" :value="department.id" :key="department.id">
                  {{ department.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Enviar menu" v-if="option_type === 'RED'">
              <b-checkbox v-model="hide_menu_option">Não enviar opção quando fora do horário de funcionamento</b-checkbox>
            </b-field>
            <div class="notification is-danger" v-if="option_type === 'MEN'">
              Para que esta opção funcione corretamente, é necessário criar
              outras opções de submenu ligadas a esta!
            </div>
            <b-field v-if="option_type === 'TAG'">
              <template #label>
                Tag
                <b-tooltip type ="is-success" size="is-small" multilined ="true" position="is-right" label = "Escolha a tag no qual será atribuída ao contato">
                  <b-icon type="is-primary" size="is-small" icon="help-circle"></b-icon>
                </b-tooltip>
              </template>
              <b-select placeholder="Selecione uma opção" v-model="tag_id" required expanded>
                <option v-for="tag in tags" :value="tag.id" :key="tag.id">
                  {{ tag.title }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Mensagem padrão para envio do menu" v-if="option_type === 'MEN'">
              <editor api-key="no-api-key" v-model="customMessageMenu" output-format="text" :init="{
                height: 200,
                menubar: false,
                plugins: ['emoticons'],
                toolbar: 'emoticons',
                branding: false,
                statusbar: false,
              }" />
            </b-field>
            <b-field :label="editorLabel" v-if="option_type === 'RED' || option_type === 'INF' || option_type === 'TAG' || option_type === 'MEN'">
              <editor api-key="no-api-key2" v-model="message" output-format="text" :init="{
                height: 200,
                menubar: false,
                plugins: ['emoticons'],
                toolbar: 'emoticons',
                branding: false,
                statusbar: false,
              }" />
            </b-field>
            <br>
            <b-field class="is-primary is-fullwidth" :class="{ 'has-name': !!files }" v-if="option_type === 'INF'">
              <b-upload class="file is-primary is-fullwidth" v-model="files" :multiple="true" :accept="acceptedFiles" drag-drop expanded>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>Arraste os arquivos ou clique aqui para adiciona-los</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags">
              <span v-for="(file, index) in filesInfo" :key="index" class="tag is-primary">
                {{ file.file_name }}
                <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
              </span>
            </div>
            <div class="field">
              <b-checkbox @input="verifyOptionValue" v-model="enabled">Opção ativa</b-checkbox>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button v-if="!!optionUpdating" class="button is-primary" @click="updateOption">
              Atualizar
            </button>
            <button v-else class="button is-primary" @click="addOption">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { mapActions, mapGetters } from 'vuex'
import multiselect from 'vue-multiselect'

const OFICIAL_ACCEPTED_AUDIO_TYPES = 'audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg'
const OFICIAL_ACCEPTED_DOCUMENT_TYPES = 'text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const OFICIAL_ACCEPTED_IMAGE_TYPES = 'image/jpeg, image/png'
const OFICIAL_ACCEPTED_VIDEO_TYPES = 'video/mp4, video/3gp'
const OFICIAL_ACCEPTED_FILES = OFICIAL_ACCEPTED_AUDIO_TYPES + ', ' + OFICIAL_ACCEPTED_DOCUMENT_TYPES + ', ' + OFICIAL_ACCEPTED_IMAGE_TYPES + ', ' + OFICIAL_ACCEPTED_VIDEO_TYPES


export default {
  name: 'ModalInsertMenuOption',
  components: {
    Editor,
    multiselect
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    optionUpdating: {
      type: Object,
      required: false
    },
    departments: {
      type: Array,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      description: '',
      enabled: true,
      option_value: '',
      department_id: '',
      message: '',
      option_type: 'RED',
      menuOptions: [],
      previous_option_id: null,
      send_static_message: false,
      files: [],
      filesInfo: [],
      info_option_send_main_menu: true,
      systems_integrations: [],
      system_integration_id: null,
      editorLabel: 'Mensagem padrão ao selecionar a opção',
      tag_id: null,
      customMessageMenu: '',
      invalidOptionValue: {
        message: '',
        style: ''
      },
      availablePhones: [],
      phones: [],
      settings: {},
      hide_menu_option: true,
      filteredDepartments: [],
      acceptedFiles: OFICIAL_ACCEPTED_FILES
    }
  },
  watch: {
    phones: function (newValue) {
      this.filteredDepartments = this.filterDepartmentByPhonesArray(newValue);

      if (this.filteredDepartments.findIndex(department => department.id === this.department_id) === -1) {
        this.department_id = null
      }
    },
    option_type: function (newValue) {
      if (newValue === 'MEN') {
        this.department_id = null
        this.tag_id = null
        this.system_integration_id = null
      } else if (newValue === 'INF') {
        this.department_id = null
        this.editorLabel = 'Mensagem informativa a ser exibida'
        this.tag_id = null
        this.system_integration_id = null
      } else if (newValue === 'INT') {
        this.department_id = null
        this.message = ''
        this.tag_id = null
      } else if (newValue === 'RED') {
        this.editorLabel = 'Mensagem padrão ao selecionar a opção'
        this.tag_id = null
        this.system_integration_id = null
      } else if (newValue === 'TAG') {
        this.editorLabel = 'Mensagem padrão ao selecionar a opção'
        this.department_id = null
        this.system_integration_id = null
      }
    },
    files: function (files) {
      this.filesInfo = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (!file) return
        const reader = new FileReader()
        // Convert the file to base64 text
        reader.readAsDataURL(file)
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          this.filesInfo.push({
            file_name: file.name,
            file_mimetype: file.type,
            file_size: Math.round(file.size / 1000) + ' kB',
            file_base64: reader.result.substr(reader.result.indexOf(',') + 1)
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['allPhonesEnabled'])
  },
  async created() {
    this.fetchSettings()
    if (this.optionUpdating) {
      this.description = this.optionUpdating.description
      this.enabled = this.optionUpdating.enabled
      this.department_id = this.optionUpdating.department_id
      this.option_value = this.optionUpdating.option_value
      this.info_option_send_main_menu = this.optionUpdating.info_option_send_main_menu
      this.hide_menu_option = this.optionUpdating.hide_menu_option

      this.message = this.optionUpdating.message
        ? this.optionUpdating.message.replaceAll('\n', '<br>')
        : ''
      this.customMessageMenu = this.optionUpdating.custom_message_menu
        ? this.optionUpdating.custom_message_menu.replaceAll('\n', '<br>')
        : ''
      this.previous_option_id = this.optionUpdating.previous_option_id
      this.tag_id = this.optionUpdating.tag_id

      if (this.optionUpdating.show_menu_after) {
        this.option_type = 'MEN'
      } else if (this.optionUpdating.send_static_message) {
        this.option_type = 'INF'
      } else if (this.optionUpdating.external_system_integration_id) {
        this.option_type = 'INT'
      } else if (this.tag_id) {
        this.option_type = 'TAG'
      } else {
        this.option_type = 'RED'
      }

      if (this.optionUpdating.ww_phone) {
        this.phones = [{ phone: this.optionUpdating.ww_phone }]
      } else {
        this.phones = []
      }

      if (this.optionUpdating.files) {
        this.optionUpdating.files.forEach(file => {
          this.filesInfo.push({
            file_name: file.file_name,
            file_mimetype: file.file_mimetype,
            file_base64: file.file_base64
          })
        })
      }
    } else {
      this.filteredDepartments = this.filterDepartmentByPhonesArray(this.phones);
    }

    this.menuOptions = this.options.filter((option) => option.show_menu_after)
    this.system_integration_id = this.optionUpdating?.external_system_integration_id
    this.systems_integrations = await this.fetchAllIntegrations();
    this.availablePhones = this.allPhonesEnabled
  },
  methods: {
    ...mapActions(['fetchAllIntegrations', 'fetchSettings']),
    filterDepartmentByPhonesArray(phoneNumbers) {
      if (!phoneNumbers || !phoneNumbers.length) return this.departments.filter(department => !department.ww_phone);

      const localPhones = phoneNumbers.map((item) => item.phone)
      return this.departments.filter(department => localPhones.includes(department.ww_phone) || !department.ww_phone)
    },
    deleteDropFile(index) {
      this.filesInfo = this.filesInfo.filter((file, idx) => index !== idx)
      this.files = this.files.filter((file, idx) => index !== idx)
    },
    cleanFile() {
      this.files = []
      this.filesInfo = []
    },
    addOption() {
      if (this.invalidOptionValue.style !== '') {
        this.$buefy.dialog.alert({
          title: 'Opção com atalho inválido',
          message:
            'O atalho informado já está vinculado à outra opção de menu ativa!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.option_type === 'RED' && !this.department_id) {
        this.$buefy.dialog.alert({
          title: 'Opção incompleta',
          message:
            'É necessário selecionar um departamento!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.option_type === 'TAG' && !this.tag_id) {
        this.$buefy.dialog.alert({
          title: 'Opção incompleta',
          message:
            'É necessário selecionar uma tag!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.option_type === 'INT' && !this.system_integration_id) {
      this.$buefy.dialog.alert({
        title: 'Integração incompleta',
        message: 
        'É necessário selecinar uma integração!',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole:'alertdialog',
        arialModal: true
      })
      return
    }

      let selectedPhones = []
      if (this.phones.length > 0) {
        selectedPhones = this.phones.map((item) => item.phone)
      }

      console.log(this.filesInfo)
      this.$emit('addOption', {
        description: this.description,
        enabled: this.enabled,
        department_id: this.department_id,
        option_value: this.option_value,
        message: this.message ? this.message.replaceAll('<br>', '\n') : '',
        show_menu_after: this.option_type === 'MEN',
        send_static_message: this.option_type === 'INF',
        previous_option_id: this.previous_option_id,
        files_info: this.filesInfo,
        info_option_send_main_menu: this.info_option_send_main_menu,
        external_system_integration_id: this.system_integration_id,
        tag_id: this.tag_id,
        custom_message_menu: this.customMessageMenu ? this.customMessageMenu.replaceAll('<br>', '\n') : '',
        phones: selectedPhones,
        hide_menu_option: this.hide_menu_option
      })
    },
    closeModal() {
      this.$emit('closeModal')
    },
    phoneUpdateHandler(phoneObj) {
      this.countryCode = phoneObj.countryCallingCode
      this.validPhone = phoneObj.nationalNumber
    },
    updateOption() {
      if (
        this.description &&
        this.description.length >= 0 &&
        this.description.length <= 2
      ) {
        this.$buefy.dialog.alert({
          title: 'Nome muito curto',
          message:
            'O <b>nome</b> informado é inválido. O novo nome deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.option_type === 'RED' && !this.department_id) {
        this.$buefy.dialog.alert({
          title: 'Opção incompleta',
          message:
            'É necessário selecionar um departamento!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.option_type === 'TAG' && !this.tag_id) {
        this.$buefy.dialog.alert({
          title: 'Opção incompleta',
          message:
            'É necessário selecionar uma tag!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.option_type === 'INT' && !this.system_integration_id) {
        this.$buefy.dialog.alert({
          title: 'Integração incompleta',
          message: 
          'É necessário selecinar uma integração!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole:'alertdialog',
          arialModal: true
        })
        return
      }

      let selectedPhones = []
      if (this.phones.length > 0) {
        selectedPhones = this.phones.map((item) => item.phone)
      }

      this.emitUpdateOption({
        id: this.optionUpdating.id,
        description: this.description,
        enabled: this.enabled,
        department_id: this.department_id,
        option_value: this.option_value,
        message: this.message ? this.message.replaceAll('<br>', '\n') : '',
        show_menu_after: this.option_type === 'MEN',
        send_static_message: this.option_type === 'INF',
        previous_option_id: this.previous_option_id,
        files_info: this.filesInfo,
        info_option_send_main_menu: this.info_option_send_main_menu,
        external_system_integration_id: this.system_integration_id,
        tag_id: this.tag_id,
        custom_message_menu: this.customMessageMenu ? this.customMessageMenu.replaceAll('<br>', '\n') : '',
        phones: selectedPhones,
        hide_menu_option: this.hide_menu_option,
        updateOption: this.updateOption
      })
    },
    emitUpdateOption(option) {
      this.$emit('updateOption', option)
    },
    verifyOptionValue() {
      const val = this.options.filter(o => (o.option_value === this.option_value && o.previous_option_id === this.previous_option_id && o.enabled && (this.phones.length === 0 ? true : this.phones.some(item => item.phone === o.ww_phone || o.ww_phone === null))))

      if (val.length > 0) {
        this.invalidOptionValue.message = 'Atalho já utilizado'
        this.invalidOptionValue.style = 'is-danger'
      } else {
        this.invalidOptionValue.message = ''
        this.invalidOptionValue.style = ''
      }
    }
  }
}
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
