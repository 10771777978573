<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Departamentos
    </hero-bar>
    <section class="section is-main-section">
      <ModalInsertDepartment
        v-if="isComponentModalActive"
        v-on:addDepartment="handleAddDepartment"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive"
        :departmentUpdating="departmentUpdating"
        v-on:updateDepartment="handleUpdateDepartment"
        :businessHours="allEnabledBusinessHours" />
      <b-loading v-if="fetchingDepartments" :is-full-page="true" v-model="fetchingDepartments" :can-cancel="false"></b-loading>
      <DepartmentsList v-else :departments='allDepartments' v-on:removeDepartment="removeDepartment" v-on:updateDepartment="openUpdateDepartment" :departmentDeletingId="departmentDeletingId" />
      <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Novo departamento" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import DepartmentsList from '@/components/departments/DepartmentsList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalInsertDepartment from '@/components/modals/ModalInsertDepartment'

export default {
  name: 'DepartmentsScreen',
  components: {
    HeroBar,
    TitleBar,
    DepartmentsList,
    FabButton,
    ModalInsertDepartment
  },
  data () {
    return {
      isComponentModalActive: false,
      departmentUpdating: null,
      departmentDeletingId: 0
    }
  },
  computed: {
    ...mapGetters(['allDepartments', 'fetchingDepartments', 'allEnabledBusinessHours', 'departmentSentQuestions']),
    titleStack () {
      return ['Cadastros', 'Departamentos']
    }
  },
  methods: {
    ...mapActions(['addDepartment', 'fetchDepartments', 'deleteDepartment', 'updateDepartment', 'fetchBusinessHours', 'fetchSentQuestions']),
    handleAddDepartment (department) {
      this.closeModal()
      this.addDepartment(department)
    },
    handleAddClick () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.departmentUpdating = null
      this.isComponentModalActive = false
    },
    async removeDepartment (department) {
      try {
        this.departmentDeletingId = department.id
        await this.deleteDepartment(department.id)
        this.$buefy.toast.open('Departamento excluido com sucesso!')
      } catch (error) {
        this.$buefy.toast.open('Não é possível excluir este departamento, possivelmente ele possui relacionamentos com mensagens e/ou tickets!')
      } finally {
        this.departmentDeletingId = 0
      }
    },
    async openUpdateDepartment (department) {
      this.departmentUpdating = department
      if (this.departmentUpdating.questions.length > 0) {
        await this.fetchSentQuestions(this.departmentUpdating.id)

        this.departmentUpdating.questions = this.departmentUpdating.questions.map(q => {
          if (this.departmentSentQuestions.indexOf(q.id) !== -1) {
            q.sent = true
          } else {
            q.sent = false
          }
          return q
        })
      }
      this.handleAddClick()
    },
    handleUpdateDepartment (department) {
      this.closeModal()
      this.updateDepartment(department).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'Não foi possível atualizar o departamento, pois o mesmo está vinculado à opções de menu, à tickets abertos ou à transferência automática.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    }
  },
  created () {
    this.fetchDepartments()
    this.fetchBusinessHours()
  }
}
</script>
