<template>
  <div>
    <table class="table-card">
      <card-component class="has-table has-mobile-sort-spaced header-card">
        <thead class="thead-card">
          <tr class="tr-card">
            <th class="th-card">
              Questão
            </th>
            <th class="th-card">
              Tipo de resposta
            </th>
            <th class="th-card">
              Tempo de resposta (minutos)
            </th>
            <th class="th-card">
              Ativo
            </th>
            <th style="width: 5%"></th>
          </tr>
        </thead>
      </card-component>

      <tbody class="tbody-card">
        <div
          v-for="q in questions"
          :key="q.auxId"
        >
          <question-inputs
            :question="q"
            v-on:updateQuestion="updateQuestion"
            v-on:deleteQuestion="deleteQuestion"
          />
        </div>
      </tbody>
    </table>
    <b-button @click="addQuestion" type="is-success" rounded icon-left="plus" class="add-link">
      Nova questão
    </b-button>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'
import QuestionInputs from '@/components/departments/QuestionInputs'

export default {
  name: 'QuestionList',
  props: {
    departmentQuestions: {
      type: Array,
      required: false
    }
  },
  components: {
    CardComponent,
    QuestionInputs
  },
  data () {
    return {
      questions: [],
      auxId: 1
    }
  },
  created () {
    this.copyProps()
  },
  watch: {
    links (newLinks, oldLinks) {
      this.checkForLinks()
      this.setAvailable()
    }
  },
  methods: {
    copyProps () {
      if (this.departmentQuestions.length <= 0 || !this.departmentQuestions) {
        this.addQuestion()
      } else {
        this.questions = JSON.parse(JSON.stringify(this.departmentQuestions))
        this.questions.forEach(q => {
          q.auxId = this.auxId
          this.auxId++
        })
      }
    },
    addQuestion () {
      this.questions.push({
        auxId: this.auxId,
        question: '',
        response_type: 'TXT',
        response_interval: 1,
        enabled: true
      })
      this.auxId++
      this.$emit('addQuestions', this.questions)
    },
    updateQuestion (q) {
      this.questions.forEach(el => {
        if (el.auxId === q.auxId) {
          el.question = q.question
          el.response_type = q.response_type
          el.response_interval = q.response_interval
          el.enabled = q.enabled
        }
      })
      this.$emit('addQuestions', this.questions)
    },
    deleteQuestion (q) {
      this.questions = this.questions.filter(el => el.auxId !== q.auxId)
      this.$emit('addQuestions', this.questions)
    }
  }
}
</script>

<style lang="scss" scoped>

.table-card {
  width: 100%;
  table-layout: fixed;
}

.header-card {
  border: 0px;
  margin-bottom: 15px;
}

.thead-card {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.tr-card {
  width: 100%;
}

.th-card:first-child {
  font-weight: 600;
  vertical-align: middle;
  padding-right: 10px;
  width: 50%;
}

.th-card:nth-child(2) {
  font-weight: 600;
  vertical-align: middle;
  width: 20%;
}

.th-card:nth-child(3) {
  font-weight: 600;
  vertical-align: middle;
  width: 20%;
}

.th-card:nth-child(4) {
  font-weight: 600;
  vertical-align: middle;
  width: 5%;
}

.tbody-card {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.add-link {
  float: right;
}

</style>
