<template>
  <div>
    <div v-if="allOptions.length > 0">
      <b-table
        :data="allOptions"
        :bordered="false"
        :striped="true"
        :narrowed="false"
        :hoverable="true"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
        scrollable>

        <b-table-column :numeric="true" field="id" label="ID" v-slot="props" sortable>
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="description" label="Descrição" v-slot="props">
          {{ props.row.description }}
        </b-table-column>

        <b-table-column field="option_value" label="Valor (atalho)" v-slot="props" sortable>
          {{ props.row.option_value }}
        </b-table-column>

        <b-table-column field="previous_option_id" label="Opção de" v-slot="props" sortable>
          {{ props.row.previous_option ? `${props.row.previous_option.id} - ${props.row.previous_option.description}` : '' }}
        </b-table-column>

        <b-table-column field="phone" label="Número da opção" v-slot="props" sortable>
          {{ props.row.ww_phone }}
        </b-table-column>

        <b-table-column field="is_active" label="Status" v-slot="props">
          <is-active-tag :active="props.row.enabled" />
        </b-table-column>

        <b-table-column field="update_delete" label="" v-slot="props">
          <dropdown-tag :isDeleting="optionDeletingId === props.row.id">
            <dropdown-item-tag @click="updateOption(props.row)">Atualizar</dropdown-item-tag>
            <dropdown-item-tag @click="confirmOptionDelete(props.row)">Excluir</dropdown-item-tag>
          </dropdown-tag>
        </b-table-column>

      </b-table>
    </div>
  </div>
</template>

<script>

import IsActiveTag from '../commons/IsActiveTag.vue'
import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'

export default {
  name: 'MenuOptionsList',
  props: {
    options: {
      type: Array,
      required: true
    },
    optionDeletingId: {
      type: Number,
      required: true
    }
  },
  components: {
    IsActiveTag,
    DropdownTag,
    DropdownItemTag
  },
  computed: {
    allOptions: {
      get () {
        return this.options
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    confirmOptionDelete (option) {
      this.$buefy.dialog.confirm({
        title: 'Excluir opção de menu',
        message: 'Deseja <b>excluir</b> este opção de menu?',
        confirmText: 'Excluir opção de menu',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeOption', option)
      })
    },
    updateOption (option) {
      this.$emit('updateOption', option)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
