<template>
  <div>
    <section class="department-modal">
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="departmentUpdating" class="modal-card-title">Atualizar informações do departamento</p>
            <p v-else class="modal-card-title">Insira o departamento a ser cadastrado</p>
          </header>
          <section class="modal-card-body">
            <b-tabs class="tabs-settings">
              <b-tab-item label="Geral">
                <b-field label="Número do departamento">
                  <b-select expanded placeholder="Número do departmento" v-model="ww_phone">
                    <option :value="null" :key="null">
                    </option>
                    <option v-for="phone in allPhonesEnabled" :value="phone.phone" :key="phone.phone">
                      {{ phone.phone }} {{ phone.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Nome">
                  <b-input type="text" v-model="name" placeholder="Nome" required>
                  </b-input>
                </b-field>
                <b-field label="Horário de funcionamento">
                  <b-select placeholder="Selecione um horário" v-model="business_hours_id" expanded>
                    <option :value="null"></option>
                    <option v-for="bh in businessHours" :value="bh.id" :key="bh.id">
                      {{ bh.description }}
                    </option>
                  </b-select>
                </b-field>
                <div class="field">
                  <b-checkbox v-model="private_messages">Mensagens privadas ao departamento</b-checkbox>
                </div>
                <div class="field">
                  <b-checkbox v-model="wait_question_response">Solicitar informações para prosseguir
                    atendimento</b-checkbox>
                </div>
                <b-field v-show="wait_question_response" label="O que fazer caso as informações não sejam passadas">
                  <b-select placeholder="Selecione uma opção" expanded v-model="invalid_question_response_action"
                    :disabled='!wait_question_response'>
                    <option value="RED" key="RED">Enviar ticket ao departamento</option>
                    <option value="CLO" key="CLO">Encerrar ticket</option>
                  </b-select>
                </b-field>
                <b-field v-show="invalid_question_response_action === 'CLO'" label="Motivo de fechamento utilizado">
                  <b-select v-if="allReasons" expanded v-model="close_reason_id"
                    :disabled="!invalid_question_response_action">
                    <option v-for="ticketCloseReason in allReasons" :value="ticketCloseReason.id"
                      :key="ticketCloseReason.title">
                      {{ ticketCloseReason.title }}
                    </option>
                  </b-select>
                </b-field>
                <div class="field">
                  <b-checkbox v-model="enabled">Departamento ativo</b-checkbox>
                </div>
              </b-tab-item>
              <b-tab-item label="Questões">
                <b-message class="warning-message" type="is-warning">
                  "Questões" são as mensagens que serão enviadas solicitando alguma informação necessária para o
                  atendimento
                </b-message>
                <QuestionList :departmentQuestions='departmentUpdating ? departmentUpdating.questions : []'
                  @addQuestions='handleNewQuestion' />
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Fechar</button>
            <button v-if="!!departmentUpdating" class="button is-primary" @click="updateDepartment">Atualizar</button>
            <button v-else class="button is-primary" @click="addDepartment">Gravar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import QuestionList from '@/components/departments/QuestionList.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalInsertContactDepartment',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    departmentUpdating: {
      type: Object,
      required: false
    },
    businessHours: {
      type: Array,
      required: true
    }
  },
  components: {
    QuestionList
  },
  created() {
    this.fetchSettings();

    if (this.departmentUpdating) {
      this.name = this.departmentUpdating.name
      this.enabled = this.departmentUpdating.enabled
      this.private_messages = this.departmentUpdating.private_messages
      this.business_hours_id = this.departmentUpdating.business_hours_id
      this.wait_question_response = this.departmentUpdating.wait_question_response
      this.invalid_question_response_action = this.departmentUpdating.invalid_question_response_action
      this.close_reason_id = this.departmentUpdating.close_reason_id
      this.questions = this.departmentUpdating.questions
      this.ww_phone = this.departmentUpdating.ww_phone
    }
  },
  data() {
    return {
      name: '',
      enabled: true,
      private_messages: false,
      business_hours_id: null,
      invalid_question_response_action: '',
      wait_question_response: false,
      close_reason_id: null,
      questions: [],
      ww_phone: null
    }
  },
  computed: {
    ...mapGetters(['allReasons', 'allPhonesEnabled'])
  },
  methods: {
    ...mapActions(['fetchReasons', 'fetchSettings']),
    addDepartment() {
      if (this.wait_question_response && (this.invalid_question_response_action === '' || !this.invalid_question_response_action)) {
        this.$buefy.dialog.alert({
          title: 'Formulário incompleto',
          message: 'Em departamentos que solicitam informações para atendimento é necessário informar <b>O que fazer caso as informações não sejam passadas</b>.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.wait_question_response && this.invalid_question_response_action === 'CLO' && !this.close_reason_id) {
        this.$buefy.dialog.alert({
          title: 'Motivo de fechamento não informado',
          message: 'É necessário informar um <b>motivo de fechamento</b>.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.$emit('addDepartment', {
        name: this.name,
        enabled: this.enabled,
        private_messages: this.private_messages,
        business_hours_id: this.business_hours_id,
        invalid_question_response_action: this.invalid_question_response_action,
        wait_question_response: this.wait_question_response,
        close_reason_id: this.close_reason_id,
        ww_phone: this.ww_phone,
        questions: this.questions.map(el => {
          delete el.auxId
          return el
        })
      })
    },
    closeModal() {
      this.$emit('closeModal')
    },
    phoneUpdateHandler(phoneObj) {
      this.countryCode = phoneObj.countryCallingCode
      this.validPhone = phoneObj.nationalNumber
    },
    updateDepartment() {
      if (this.name && this.name.length >= 0 && this.name.length <= 2) {
        this.$buefy.dialog.alert({
          title: 'Nome muito curto',
          message: 'O <b>nome</b> informado é inválido. O novo nome deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.wait_question_response && (this.invalid_question_response_action === '' || !this.invalid_question_response_action)) {
        this.$buefy.dialog.alert({
          title: 'Formulário incompleto',
          message: 'Em departamentos que solicitam informações para atendimento é necessário informar <b>O que fazer caso as informações não sejam passadas</b>.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.wait_question_response && this.invalid_question_response_action === 'CLO' && !this.close_reason_id) {
        this.$buefy.dialog.alert({
          title: 'Motivo de fechamento não informado',
          message: 'É necessário informar um <b>motivo de fechamento</b>.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.emitUpdateDepartment({
        id: this.departmentUpdating.id,
        name: this.name,
        enabled: this.enabled,
        private_messages: this.private_messages,
        business_hours_id: this.business_hours_id,
        invalid_question_response_action: this.invalid_question_response_action,
        wait_question_response: this.wait_question_response,
        close_reason_id: this.close_reason_id,
        ww_phone: this.ww_phone,
        questions: this.questions.map(el => {
          delete el.auxId
          el.department_id = this.departmentUpdating.id
          return el
        })
      })
    },
    emitUpdateDepartment(department) {
      this.$emit('updateDepartment', department)
    },
    handleNewQuestion(questions) {
      this.questions = questions
    }
  },
  watch: {
    wait_question_response(newValue) {
      if (!newValue) {
        this.invalid_question_response_action = ''
        this.close_reason_id = null
      }
    },
    invalid_question_response_action(newValue) {
      if (newValue === 'CLO') {
        this.fetchReasons()
      }
    }
  }
}
</script>

<style>
.department-modal .modal .animation-content .modal-card {
  overflow: visible !important;
}

.department-modal .modal-card-body {
  overflow: visible !important;
}

.department-modal .tabs-settings .is-active a {
  border-bottom-color: #00A48D !important;
  color: #00A48D !important;
}

.modal-card-foot {
  justify-content: flex-end !important;
}
</style>
