<template>
  <div>
    <card-component title="" class="has-table has-mobile-sort-spaced body-card">
      <tr class="tr-card">
        <td class="td-card">
          <b-field expanded>
            <b-input v-model="message" :disabled="sent" expanded @change.native="updateQuestion" />
          </b-field>
        </td>

        <td class="td-card">
          <b-field expanded>
            <b-select placeholder="Selecione uma opção" expanded
              v-model="response_type" :disabled="sent" @change.native="updateQuestion">
              <option value="TXT" key="TXT">Texto</option>
              <option value="FIL" key="FIL">Arquivo</option>
            </b-select>
          </b-field>
        </td>

        <td class="td-card">
          <b-field expanded>
            <b-input v-model="response_interval" min="1" type="number" @change.native="updateQuestion" />
          </b-field>
        </td>

        <td class="td-card">
          <b-checkbox v-model="enabled" />
        </td>

        <td>
          <b-button v-show="!sent" icon-right="delete" class="delete-button" @click="deleteQuestion" />
        </td>
      </tr>
    </card-component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'

export default {
  name: 'QuestionInputs',
  props: {
    question: {
      type: Object,
      required: false
    }
  },
  components: {
    CardComponent
  },
  data () {
    return {
      message: '',
      response_type: '',
      response_interval: null,
      currentQuestion: null,
      enabled: true,
      sent: false
    }
  },
  created () {
    if (this.question) {
      this.message = this.question.question
      this.response_type = this.question.response_type
      this.response_interval = this.question.response_interval
      this.enabled = this.question.enabled
      this.sent = this.question.sent
      this.currentQuestion = JSON.parse(JSON.stringify(this.question))
    }
  },
  watch: {
    //
  },
  methods: {
    updateQuestion () {
      this.currentQuestion.question = this.message
      this.currentQuestion.response_interval = this.response_interval
      this.currentQuestion.response_type = this.response_type
      this.currentQuestion.enabled = this.enabled
      this.$emit('updateQuestion', this.currentQuestion)
    },
    deleteQuestion () {
      this.$emit('deleteQuestion', this.currentQuestion)
    }
  }
}
</script>

<style lang="scss" scoped>

.body-card {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 20px;
  margin-top: 10px;
  transition: 0.3s;
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 0px
}

.tr-card {
  width: 100%;
  display: table;
}

.td-card:first-child {
  padding-right: 10px;
  width: 50%;
}

.td-card:nth-child(2) {
  padding-right: 10px;
  width: 20%;
}

.td-card:nth-child(3) {
  padding-right: 10px;
  width: 20%;
}

.td-card:nth-child(4) {
  padding-right: 10px;
  vertical-align: middle;
  width: 5%;
}

b-select {
  border: 0px;
}

.delete-button {
  color: rgb(255, 70, 70);
  border: 0px;
  font-size: 18px;
}

.delete-button:hover {
  color: red;
}
</style>
