<template>
  <div>
    <div v-if="allDepartments.length > 0">
      <b-table :data="allDepartments" :bordered="false" :striped="true" :narrowed="false" :hoverable="true"
        :loading="false" :focusable="false" :mobile-cards="true">

        <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Nome" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="private_messages" label="Mensagens privadas" v-slot="props">
          <YesNoTag :isYes="props.row.private_messages" />
        </b-table-column>

        <b-table-column field="business_hours" label="Horário de funcionamento" v-slot="props">
          {{ props.row.business_hours ? props.row.business_hours.description : '' }}
        </b-table-column>

        <b-table-column field="send_questions" label="Solicitar info. p/ atendimento" v-slot="props">
          <YesNoTag :isYes="props.row.wait_question_response" />
        </b-table-column>

        <b-table-column field="business_hours" label="Encerrar ticket por falta de info." v-slot="props">
          <YesNoTag :isYes="props.row.invalid_question_response_action === 'CLO'" />
        </b-table-column>

        <b-table-column field="business_hours" label="Num. do departamento" v-slot="props">
          {{ props.row.ww_phone }}
        </b-table-column>

        <b-table-column field="is_active" label="Status" v-slot="props">
          <is-active-tag :active="props.row.enabled" />
        </b-table-column>
        <b-table-column field="update_delete" label="" v-slot="props">
          <dropdown-tag :isDeleting="departmentDeletingId === props.row.id">
            <dropdown-item-tag @click="updateDepartment(props.row)">Atualizar</dropdown-item-tag>
            <dropdown-item-tag @click="confirmDepartmentDelete(props.row)">Excluir</dropdown-item-tag>
          </dropdown-tag>
        </b-table-column>

      </b-table>
    </div>
  </div>
</template>

<script>
import YesNoTag from '../commons/YesNoTag.vue'
import IsActiveTag from '../commons/IsActiveTag.vue'
import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'

export default {
  name: 'DepartmentsList',
  props: {
    departments: {
      type: Array,
      required: true
    },
    departmentDeletingId: {
      type: Number,
      required: true
    }
  },
  components: {
    YesNoTag,
    IsActiveTag,
    DropdownTag,
    DropdownItemTag
  },
  computed: {
    allDepartments: {
      get () {
        return this.departments
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    confirmDepartmentDelete (department) {
      this.$buefy.dialog.confirm({
        title: 'Excluir departamento',
        message: 'Deseja <b>excluir</b> este departamento?',
        confirmText: 'Excluir departamento',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeDepartment', department)
      })
    },
    updateDepartment (department) {
      this.$emit('updateDepartment', department)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
