<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Opções de menu
    </hero-bar>
    <section class="section is-main-section">
      <ModalInsertMenuOption
        v-if="isComponentModalActive"
        v-on:addOption="handleAddOption"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive"
        :optionUpdating="optionUpdating"
        v-on:updateOption="handleUpdateOption"
        :departments="getEnabledDepartments"
        :tags="allEnabledTags"
        :options="allOptions" />
      <b-loading v-if="fetchingOptions" :is-full-page="true" v-model="fetchingOptions" :can-cancel="false"></b-loading>

      <b-loading v-if="fetchingLocally" :is-full-page="true" v-model="fetchingLocally" :can-cancel="false"></b-loading>
      <MenuOptionsList v-else :options='allOptions' v-on:removeOption="removeOption" v-on:updateOption="openUpdateOption" :optionDeletingId="optionDeletingId" />
      <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Nova opção de menu" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import MenuOptionsList from '@/components/menu_options/MenuOptionsList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalInsertMenuOption from '@/components/modals/ModalInsertMenuOption'
import { get } from '../utils/api'

export default {
  name: 'MenuOptions',
  components: {
    HeroBar,
    TitleBar,
    MenuOptionsList,
    FabButton,
    ModalInsertMenuOption
  },
  data () {
    return {
      isComponentModalActive: false,
      optionUpdating: null,
      fetchingLocally: false,
      optionDeletingId: 0
    }
  },
  computed: {
    ...mapGetters(['allOptions', 'fetchingOptions', 'allDepartments', 'allEnabledTags']),
    titleStack () {
      return ['Cadastros', 'Opções de menu']
    },
    getEnabledDepartments () {
      return this.allDepartments.filter(d => d.enabled)
    }
  },
  methods: {
    ...mapActions(['addOption', 'fetchOptions', 'deleteOption', 'updateOption', 'fetchDepartments', 'fetchEnabledTags', 'fetchSettings']),
    handleAddOption (option) {
      this.closeModal()
      this.addOption(option)
    },
    handleAddClick () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.optionUpdating = null
      this.isComponentModalActive = false
    },
    async removeOption (option) {
      try {
        this.optionDeletingId = option.id
        await this.deleteOption(option.id)
        this.$buefy.toast.open('Opção de menu excluida com sucesso!')
      } catch (error) {
        this.$buefy.toast.open('Não é possível excluir esta opção de menu, possivelmente ele possui relacionamentos com mensagens e/ou tickets!')
      } finally {
        this.optionDeletingId = 0
      }
    },
    async openUpdateOption (option) {
      this.fetchingLocally = true

      try {
        const files = await get(`options/${option.id}/files_full`)
        option.files = files.data
        this.optionUpdating = option
        this.handleAddClick()
      } finally {
        this.fetchingLocally = false
      }
    },
    handleUpdateOption (option) {
      option.files = option.files_info
      option.delete_all_previous_files = true

      this.closeModal()
      this.updateOption(option).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'Ocorreu um <b>erro</b> ao atualizar a opção de menu. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    }
  },
  created () {
    this.fetchOptions()
    this.fetchDepartments()
    this.fetchEnabledTags()
    this.fetchSettings()
  }
}
</script>
